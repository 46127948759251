import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import React, { useState } from 'react';
import AdditionalInformationStep from "./additionalnformationStep";
import ShipmentDetailsStep from "./shippmentDetailStep";
import ContactForm from "../contact/ContactForm";
import ContactInformationStep from "./contactInformationStep";
function WizardForm({ props, formStyle }) {

  const [shipmentType, setShipmentType] = useState('');
  const [shipmentOrigin, setShipmentOrigin] = useState('');
  const [date, setDate] = useState('');
  const [shipmentDestination, setShipmentDestination] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  const tabChanged = ({
    prevIndex,
    nextIndex,
  }) => {
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };

  return (<>
    <FormWizard
      shape="circle"
      stepSize="sm"
      color="#6441A3"
      onComplete={handleComplete}
      onTabChange={tabChanged}
    >
      <FormWizard.TabContent title="Shippment details" icon="ti-truck">
        <form>

          <div className="form-group">
            <label className="text-left">Shipment Type</label>
            <select
              className="transparent-border"
              value={shipmentType}
              onChange={(e) => setShipmentType(e.target.value)}
              required
            >
              <option value="">Select Shipment Type</option>
              <option value="Truckload">Truckload</option>
              <option value="Less Than Truckload">Less Than Truckload</option>
              <option value="Drayage">Drayage</option>
              <option value="Intermodal">Intermodal</option>
            </select>
          </div>

          <div className="form-group">
            <label>Shipment Origin</label>
            <input
              type="text"
              value={shipmentOrigin}
              onChange={(e) => setShipmentOrigin(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Shipment Destination</label>
            <input
              type="text"
              value={shipmentDestination}
              onChange={(e) => setShipmentDestination(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label>Service Type</label>
            <select
              className="transparent-border"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
              required
            >
              <option value="">Select Service Type</option>
              <option value="Commercial">Commercial</option>
              <option value="Residential">Residential</option>
            </select>
          </div>
        </form>
      </FormWizard.TabContent>

      <FormWizard.TabContent title="Additional Info" icon="ti-settings">

        <AdditionalInformationStep />
      </FormWizard.TabContent>
      <FormWizard.TabContent title="Contact Information" icon="ti-user">
        <ContactInformationStep />

      </FormWizard.TabContent>
    </FormWizard>

    <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
  </>
  );
}

export default WizardForm;