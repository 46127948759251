import React from 'react';
import SEO from "../../common/SEO";
import SectionTitle from "../sectionTitle/SectionTitle";
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import Copyright from '../../common/footer/Copyright';
import ContactForm from "../contact/ContactForm";
import WizardForm from './wizardForm';

const Quote = () => {
    return (
        <>
            <SEO title="Request A Quote" />
            <div className="main-content">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle = "Get Pricing"
                                    title="Request A Quote"
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 mx-auto">
                            <WizardForm formStyle="contact-form-1" />
                        </div>
                    </div>
                </div>

            </div>
            <FooterTwo />
            <Copyright />
        </>
    )
}
export default Quote;

