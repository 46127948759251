import React, { useState } from 'react';

const AdditionalInformationStep = ({ onPrevious, onNext }) => {
  const [commodity, setCommodity] = useState('');
  const [totalWeight, setTotalWeight] = useState('');
  const [pieceCount, setPieceCount] = useState('');
  const [palletWidth, setPalletWidth] = useState('');
  const [palletLength, setPalletLength] = useState('');
  const [palletHeight, setPalletHeight] = useState('');
  const [equipment, setEquipment] = useState('');
  const [temperature, setTemperature] = useState('');
  const [additionalRequirements, setAdditionalRequirements] = useState('');
  const [otherDetails, setOtherDetails] = useState('');

  const handlePrevious = (e) => {
    e.preventDefault();
    onPrevious();
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      commodity,
      totalWeight,
      pieceCount,
      palletWidth,
      palletLength,
      palletHeight,
      equipment,
      temperature,
      additionalRequirements,
      otherDetails,
    });
  };

  return (
    <form onSubmit={handlSubmit}>
      <div className="form-group">
        <label>Commodity</label>
        <input
          type="text"
          value={commodity}
          onChange={(e) => setCommodity(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Total Weight</label>
        <input
          type="text"
          value={totalWeight}
          onChange={(e) => setTotalWeight(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Piece Count</label>
        <input
          type="number"
          value={pieceCount}
          onChange={(e) => setPieceCount(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Pallet Width</label>
        <input
          type="text"
          value={palletWidth}
          onChange={(e) => setPalletWidth(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Pallet Length</label>
        <input
          type="text"
          value={palletLength}
          onChange={(e) => setPalletLength(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Pallet Height</label>
        <input
          type="text"
          value={palletHeight}
          onChange={(e) => setPalletHeight(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Equipment</label>
        <input
          type="text"
          value={equipment}
          onChange={(e) => setEquipment(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Temperature</label>
        <input
          type="text"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label>Additional Requirements</label>
        <textarea
          value={additionalRequirements}
          onChange={(e) => setAdditionalRequirements(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Other Details</label>
        <textarea
          value={otherDetails}
          onChange={(e) => setOtherDetails(e.target.value)}
        />
      </div>

      
    </form>
  );
};

export default AdditionalInformationStep;